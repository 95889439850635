import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { TranslateService } from '@ngx-translate/core';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ProductEntity } from 'src/app/shared/Entities/product.entity copy';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-application-terms',
  templateUrl: './application-terms.component.html',
  styleUrls: ['./application-terms.component.scss'],
})
export class ApplicationTermsComponent implements OnInit {
  public listProds: ProductEntity[] = [];
  public product: ProductEntity;
  public isNafedhExisted : boolean = false;

  constructor( private parametrageService: ParametrageService, private router: Router, private sharedService: SharedService, private translate: TranslateService ) {}

  ngOnInit() {
    let prod = new ProductEntity();
    let loans = this.sharedService.getListLoans();
    let filterNonSynchroLoans = loans.filter(res => !res.accountNumber);
    if (filterNonSynchroLoans.length > 0){this.isNafedhExisted = true}
    this.parametrageService.getProducts(prod).subscribe((res) => {
      this.listProds = [...res];
      this.product = this.listProds[0];
    });
  }

  checkProductsBeforeRedirection() {

    if (!this.isNafedhExisted){
      if (this.listProds.length > 1) {
        this.router.navigate(['/' + PathConstants.PATH_PRODUCT_LIST]);
      } else {
        this.sharedService.setProduct(this.listProds[0]);
        this.router.navigate(['/' + PathConstants.PATH_NAFEDH]);
      }
    }else {
      this.sharedService.showInfo(this.translate.instant('INFO.loan_in_nafedh'))
    }



  }

  downloadNafedh(){
    const iosUrl = 'https://apps.apple.com/sa/app/%D9%86%D9%81%D8%A7%D8%B0-nafath/id1598909871';
    const androidUrl = 'https://play.google.com/store/apps/details?id=sa.gov.nic.myid&hl=fr';
    const os = this.sharedService.getDeviceInfo().os.toLowerCase();
    const linkToOpen = os === 'ios' ? iosUrl : androidUrl;
    Browser.open({ url: linkToOpen });
  }

}
