import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';
import { UserEntity } from '../shared/Entities/user.entity';
import { AcmURLConstants } from '../shared/mb-constants/mb-url-constants';
import { CredentialsDTO } from '../shared/Entities/credentials.entity';

/**
 * The Authentification service
 */
@Injectable({
  providedIn: 'root',
})
export class AuthentificationService {
  inactivityTimeout: any;
  public storedToken: string;
  private isLoggedIn = false;
  private userActivitySubject = new Subject<void>();
  userActivity$: Observable<void> = this.userActivitySubject.asObservable();

  /**
   * constructor
   * @param HttpClient httpClient
   */
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private sharedService: SharedService
  ) {
    // set token if saved in local storage
    this.storedToken = localStorage.getItem('currentUser');
  }

  /**
   *  Trigger User Activity
   **/
  onUserActivity() {
    this.userActivitySubject.next();
  }

  /**
   * Logout
   */
  logout() {
    this.isLoggedIn = false;
    let undefinedUser = new UserEntity();
    this.sharedService.setUser(undefinedUser);
    localStorage.clear();
    location.reload();
    // localStorage.setItem('lang', 'ar');
    // this.router.navigate(['/login']);
  }

  login(username: string, password: string) {
    const data =
      'grant_type=password&username=' + username + '&password=' + password;
    let reqHeader = new HttpHeaders();
    const encodedCredentials = btoa(
      environment.authentificationUserName +
        ':' +
        environment.authentificationPassword
    );
    reqHeader = reqHeader
      .append('Content-Type', 'application/x-www-form-urlencoded')
      .append('Authorization', 'Basic ' + encodedCredentials);
    this.isLoggedIn = true;
    return this.httpClient
      .post(
        AcmURLConstants.AUTHENTICATION_URL_API + data,
        {},
        { headers: reqHeader }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error.error);
        })
      );
  }

  newLoginMethod(credentialsDTO: CredentialsDTO): Observable<any> {
    return this.httpClient.post<any>(AcmURLConstants.LOGIN, credentialsDTO);
  }

  /**
   * get curentUser
   */
  curentUser() {
    return this.httpClient.get<UserEntity>(AcmURLConstants.USER_PARAMETRAGE);
  }
  /**
   * resetPwd
   * @param userDTO UserEntity
   */
  resetPwd(userDTO: UserEntity): Observable<UserEntity> {
    return this.httpClient.post<UserEntity>(AcmURLConstants.RESET_PWD, userDTO);
  }
  /**
   * changePwd
   * @param userDTO UserEntity
   */
  changePwd(userDTO: UserEntity): Observable<UserEntity> {
    return this.httpClient.post<UserEntity>(
      AcmURLConstants.UPDATE_PWD,
      userDTO
    );
  }

  /**
   * Update Default Lang for user
   */
  updateDefaultLangUser(userEntity: UserEntity): Observable<UserEntity> {
    return this.httpClient.post<UserEntity>(
      AcmURLConstants.UPDATE_DEFAULT_LANG,
      userEntity
    );
  }

  public getIsLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  public setIsLoggedIn(value: boolean): void {
    this.isLoggedIn = value;
  }
  getUsers(userEntity: UserEntity): Observable<UserEntity[]> {
    return this.httpClient.post<UserEntity[]>(
      AcmURLConstants.FIND_USERS,
      userEntity
    );
  }

  uploadAvatar(image: any, username: string): Observable<any> {
    return this.httpClient.put<UserEntity>(
      environment.apiUrl + 'authentication-service/users/avatar/' + username,
      image
    );
  }

  updateFirstLogin(userDTO: UserEntity): Observable<UserEntity> {
    return this.httpClient.post<UserEntity>(
      AcmURLConstants.UPDATE_FIRST_LOGIN,
      userDTO
    );
  }

  updateNewForgottenPassword(userDTO: UserEntity): Observable<UserEntity> {
    return this.httpClient.post<UserEntity>(
      AcmURLConstants.UPDATE_FORGOTTEN_PWD,
      userDTO
    );
  }

}
