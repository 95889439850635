import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreditService } from 'src/app/ib-services/credit.service';
import { DocumentService } from 'src/app/ib-services/document.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { DocumentEntity } from 'src/app/shared/Entities/Document.entity';
import { DocumentTypeEntity } from 'src/app/shared/Entities/DocumentType.entity';
import { LoanDocumentEntity } from 'src/app/shared/Entities/LoanDocument.entity';
import { SettingDocumentTypeEntity } from 'src/app/shared/Entities/SettingDocumentType.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { AcmConstants } from 'src/app/shared/mb-constants/mb-constants';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-required-documents',
  templateUrl: './required-documents.component.html',
  styleUrls: ['./required-documents.component.scss'],
})
export class RequiredDocumentsComponent implements OnInit {
  @Input() step: string;
  @Output() sendOTPClicked: EventEmitter<void> = new EventEmitter<void>();

  private loan: LoanEntityIB;
  private customer: CustomerEntity;
  public loanDocuments: DocumentTypeEntity[] = [];
  private document: any;
  private settingDocTypeMltipleCustomer: SettingDocumentTypeEntity[] = [];
  private typesDocuments: DocumentTypeEntity[] = [];
  private settingDocTypeMltipleLoan: SettingDocumentTypeEntity[] = [];
  public addDocumentForm: FormGroup;
  private costumerDocuments: DocumentTypeEntity[] = [];
  private documentFormCustomer: FormGroup;
  public documentsToRemove: LoanDocumentEntity[] = [];
  private allDocuments: DocumentTypeEntity[] = [];
  private documentFormLoan: FormGroup;
  public documents: DocumentEntity[];
  public documentForm: FormGroup;
  public maxSizeFileUpload: number = 2097152;
  public mobileViewUrl: string;
  public lang: string;
  public showContract: boolean = false;
  public contractSrc: string;
  public isCheckedDocumentConditions: Boolean = false;
  public zoomLevel: number = 0.8;
  public stringJson: string;
  public customerEnt: CustomerEntity;

  constructor( public formBuilder: FormBuilder, public sharedService: SharedService, private router: Router,private translate : TranslateService,
    private loanService: LoanService, private documentService: DocumentService,private datePipe: DatePipe) {}

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    const loan: LoanEntityIB = new LoanEntityIB();
    this.loan = this.sharedService.getLoanDeal();
    this.customer = this.loan.customerDTO;
    loan.productId = this.loan.productId;
    this.documentService.getRequiredDocument(loan).subscribe((documentTypes) => {
        documentTypes.map((value) => {
          const documentType: DocumentTypeEntity = new DocumentTypeEntity();
          documentType.settingDocumentType = value;
          documentType.title = value.libelle;
          documentType.mandatory = value.mandatory;
          documentType.name = '-';
          documentType.date = '-';
          documentType.file = '';
          documentType.idfile = '';
          documentType.idDocument = null;
          documentType.documentIndex = 1;
          this.typesDocuments.push(documentType);
          if (value.uniqueness === false) {
            if (
              value.categorie === AcmConstants.TYPE_DOCUMENT_CATEGORIE_CUSTOMER
            ) {
              this.settingDocTypeMltipleCustomer.push(value);
            } else if (
              value.categorie === AcmConstants.TYPE_DOCUMENT_CATEGORIE_LOAN
            ) {
              this.settingDocTypeMltipleLoan.push(value);
            }
          }
        });
        this.typesDocuments.map((type) => {
          if (
            type.settingDocumentType.categorie === AcmConstants.TYPE_DOCUMENT_CATEGORIE_CUSTOMER
          ) {
            this.costumerDocuments.push(type);
          } else if (
            type.settingDocumentType.categorie === AcmConstants.TYPE_DOCUMENT_CATEGORIE_LOAN
          ) {
            this.loanDocuments.push(type);
          }
        });
        this.createForm();
        this.fillLoanDocument();
        this.documentForm = this.formBuilder.group({});
        for (let i = 0; i < this.loanDocuments.length; i++) {
          this.documentForm.addControl('documentInput' + i,new FormControl(''));
        }

      });
  }

  fillCustomerDocument() {
    const document: LoanDocumentEntity = new LoanDocumentEntity();
    document.idCustomer = this.customer.ibCustomerId;
    document.loanId = this.loan.idIbLoan;
    document.settingDocumentTypeDTO = new SettingDocumentTypeEntity();
    document.settingDocumentTypeDTO.categorie =
      AcmConstants.TYPE_DOCUMENT_CATEGORIE_CUSTOMER;
    this.documentService
      .getDocumentsByLoan(document)
      .subscribe((arrayDocuments) => {
        if (arrayDocuments.length > 0) {
          arrayDocuments.map((loanDocument) => {
            let found = false;
            this.costumerDocuments.map((type) => {
              if (
                loanDocument.titre === type.settingDocumentType.libelle &&
                loanDocument.documentIndex === type.documentIndex
              ) {
                type.name = loanDocument.name;
                type.settingDocumentType = loanDocument.settingDocumentTypeDTO;
                type.date = this.datePipe.transform(loanDocument.dateCreation,'yyyy/MM/dd');
                type.idfile = loanDocument.idDocumentGED;
                type.file = loanDocument.idDocumentGED;
                type.idDocument = loanDocument.idDocument;
                type.documentIndex = loanDocument.documentIndex;
                found = true;
              }
            });
            if (!found) {
              if (loanDocument.settingDocumentTypeDTO.categorie === AcmConstants.TYPE_DOCUMENT_CATEGORIE_CUSTOMER) {
                const newDocument: DocumentTypeEntity = new DocumentTypeEntity();
                newDocument.settingDocumentType = loanDocument.settingDocumentTypeDTO;
                newDocument.title = loanDocument.titre;
                newDocument.name = loanDocument.name;
                newDocument.date = this.datePipe.transform(loanDocument.dateCreation,'yyyy/MM/dd');
                newDocument.idfile = loanDocument.idDocumentGED;
                newDocument.file = loanDocument.idDocumentGED;
                newDocument.idDocument = loanDocument.idDocument;
                newDocument.documentIndex = loanDocument.documentIndex;
                this.costumerDocuments.push(newDocument);
              }
            }
          });
          this.check();
        } else {
          const documentIdCustomer: LoanDocumentEntity =
            new LoanDocumentEntity();
          documentIdCustomer.idCustomer = this.customer.ibCustomerId;
          documentIdCustomer.processLoanDocuments = true;
          documentIdCustomer.settingDocumentTypeDTO = new SettingDocumentTypeEntity();
          documentIdCustomer.settingDocumentTypeDTO.categorie = AcmConstants.TYPE_DOCUMENT_CATEGORIE_CUSTOMER;
          this.documentService.getDocumentsByLoan(documentIdCustomer).subscribe((arrayDocumentsCustomer) => {
              if (arrayDocumentsCustomer.length > 0) {
                arrayDocumentsCustomer.map((customerDocument) => {
                  let found = false;
                  if (customerDocument.loanId === null) {
                    this.costumerDocuments.map((type) => {
                      if (
                        customerDocument.titre ===
                          type.settingDocumentType.libelle &&
                        customerDocument.documentIndex === type.documentIndex
                      ) {
                        type.name = customerDocument.name;
                        type.settingDocumentType = customerDocument.settingDocumentTypeDTO;
                        type.date = this.datePipe.transform(customerDocument.dateCreation,'yyyy/MM/dd');
                        type.idfile = customerDocument.idDocumentGED;
                        type.file = customerDocument.idDocumentGED;
                        type.idDocument = null;
                        type.documentIndex = customerDocument.documentIndex;
                        type.exist = true;
                        found = true;
                      }
                    });
                    if (!found) {
                      if (customerDocument.settingDocumentTypeDTO.categorie === AcmConstants.TYPE_DOCUMENT_CATEGORIE_CUSTOMER ) {
                        const newDocument: DocumentTypeEntity = new DocumentTypeEntity();
                        newDocument.settingDocumentType = customerDocument.settingDocumentTypeDTO;
                        newDocument.title = customerDocument.titre;
                        newDocument.name = customerDocument.name;
                        newDocument.date = this.datePipe.transform(customerDocument.dateCreation,'yyyy/MM/dd');
                        newDocument.idfile = customerDocument.idDocumentGED;
                        newDocument.file = customerDocument.idDocumentGED;
                        newDocument.idDocument = null;
                        newDocument.documentIndex = customerDocument.documentIndex;
                        newDocument.exist = true;
                        this.costumerDocuments.push(newDocument);
                      }
                    }
                  }
                });
                this.check();
              }
            });
        }
      });
  }

  fillLoanDocument() {
    const document: LoanDocumentEntity = new LoanDocumentEntity();
    document.loanId = this.loan.idIbLoan;
    this.documentService.getDocumentsByLoan(document).subscribe((data) => {
      data.map((loanDocument) => {
        let found = false;
        this.loanDocuments.map((type) => {
          if (
            loanDocument.titre === type.settingDocumentType.libelle &&
            loanDocument.documentIndex === type.documentIndex
          ) {
            type.name = loanDocument.name;
            type.settingDocumentType = loanDocument.settingDocumentTypeDTO;
            type.date = this.datePipe.transform(
              loanDocument.dateCreation,
              'yyyy/MM/dd'
            );
            type.idfile = loanDocument.idDocumentGED;
            type.file = loanDocument.idDocumentGED;
            type.idDocument = loanDocument.idDocument;
            type.mandatory = loanDocument.mandatory;
            type.documentIndex = loanDocument.documentIndex;
            found = true;
          }
        });
        if (!found) {
          // if (loanDocument.settingDocumentTypeDTO.categorie !== AcmConstants.TYPE_DOCUMENT_CATEGORIE_LOAN) {
          const newDocument: DocumentTypeEntity = new DocumentTypeEntity();
          newDocument.settingDocumentType = loanDocument.settingDocumentTypeDTO;
          newDocument.title = loanDocument.titre;
          newDocument.name = loanDocument.name;
          newDocument.date = this.datePipe.transform(
            loanDocument.dateCreation,
            'yyyy/MM/dd'
          );
          newDocument.idfile = loanDocument.idDocumentGED;
          newDocument.file = loanDocument.idDocumentGED;
          newDocument.idDocument = loanDocument.idDocument;
          newDocument.mandatory = loanDocument.mandatory;
          newDocument.documentIndex = loanDocument.documentIndex;
          this.documentFormLoan.addControl(
            'documentInput' + this.loanDocuments.length,
            new FormControl('')
          );
          if(newDocument.settingDocumentType.id=== 100){
            this.loanDocuments.push(newDocument);
          }

        }
        // }
      });
      this.check();
    });
  }

  check() {
    this.allDocuments = [];
    this.costumerDocuments.map((value) => {
      if (typeof value.file === 'string' && value.file !== '') {
        this.documentService
          .getDocument(value.file, this.loan.loanId.toString(), null)
          .subscribe(
            async function (val, result) {
              const file0: File = new File([result], val.name);
              val.file = file0;
              this.allDocuments.push(val);
            }.bind(this, value)
          );
      } else {
        this.allDocuments.push(value);
      }
    });
    this.loanDocuments.map((value) => {
      if (value.file !== '' && value.idDocument === null) {
        this.allDocuments.push(value);
      }
    });
  }

  createForm() {
    this.addDocumentForm = this.formBuilder.group({fileInput: [''],});
    this.documentFormCustomer = this.formBuilder.group({});
    for (let i = 0; i < this.costumerDocuments.length; i++) {
      this.documentFormCustomer.addControl('documentInput' + i,new FormControl(''));
    }
    this.documentFormLoan = this.formBuilder.group({});
    for (let i = 0; i < this.loanDocuments.length; i++) {
      this.documentFormLoan.addControl('documentInput' + i,new FormControl(''));
    }
  }

  fetchInitDocuments() {
    this.documents = JSON.parse(this.stringJson);
    this.documentForm = this.formBuilder.group({});
  }

  view(file, idDocumentGED, index) {
    if (idDocumentGED !== null && idDocumentGED !== '') {
      this.documentService
        .getDocument(idDocumentGED, this.loan.idIbLoan.toString(), '')
        .subscribe((res: any) => {
          if (res instanceof Blob) {
            if (res.size == 0) {
              return;
            }
            const fileURL = URL.createObjectURL(res);
            this.contractSrc = fileURL;
            this.showContract = true;
          }
        });
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileURL = reader.result as string;
        this.contractSrc = fileURL;
        this.showContract = true;
      };
      reader.readAsDataURL(file);
    }
  }

  closeContract() {
    this.showContract = false;
  }

  getMimeType(fileExtension: string) {
    switch (fileExtension) {
      case 'pdf':
        return this.sharedService.typeMimes[0];
      case 'docx':
        return this.sharedService.typeMimes[7];
      case 'doc':
        return this.sharedService.typeMimes[1];
      case 'xlsx':
        return this.sharedService.typeMimes[8];
      case 'xls':
        return this.sharedService.typeMimes[2];
      case 'pptx':
        return this.sharedService.typeMimes[5];
      case 'ppt':
        return this.sharedService.typeMimes[6];
      case 'jpeg':
        return this.sharedService.typeMimes[3];
      case 'png':
        return this.sharedService.typeMimes[4];
      case 'jpg':
        return this.sharedService.typeMimes[3];
      default:
        return '';
    }
  }

  /** Delete document */
  remove(i: number) {
    this.loanDocuments[i].file = new File([], this.loanDocuments[i].title);
    const fileInput = document.querySelectorAll('.form-control.cpl')[
      i
    ] as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  onFileSelected(event: any, selectedDocument) {
    if (event.target.files.length > 0) {
      this.document = {
        name: event.target.files[0].name,
        settingDocumentType: selectedDocument.settingDocumentType,
        title: '' + selectedDocument.title,
        date: this.datePipe.transform(Date.now(), 'yyyy/MM/dd'),
        file: event.target.files[0],
        mandatory: selectedDocument.mandatory,
        documentIndex: selectedDocument.documentIndex,
        category: AcmConstants.LOAN,
        password: null,
      };
      this.addDocument(this.document);
    }
  }

  addDocument(uploadedDocument) {
    if (uploadedDocument.category === AcmConstants.CUSTOMER_CATEGORY_CUSTOMER) {
      let foundCustomer = false;
      this.costumerDocuments.map((documentTypeEntity) => {
        if (
          documentTypeEntity.title === uploadedDocument.title &&
          documentTypeEntity.documentIndex === uploadedDocument.documentIndex
        ) {
          documentTypeEntity.name = uploadedDocument.name;
          documentTypeEntity.date = uploadedDocument.date;
          documentTypeEntity.file = uploadedDocument.file;
          documentTypeEntity.settingDocumentType =
            uploadedDocument.settingDocumentType;
          documentTypeEntity.mandatory = uploadedDocument.mandatory;
          documentTypeEntity.idfile = '';
          documentTypeEntity.idDocument = null;
          documentTypeEntity.exist = false;
          documentTypeEntity.password = uploadedDocument.password;
          foundCustomer = true;
        }
      });
      if (!foundCustomer) {
        const documentTypeEntity: DocumentTypeEntity = new DocumentTypeEntity();
        documentTypeEntity.title = uploadedDocument.title;
        documentTypeEntity.name = uploadedDocument.name;
        documentTypeEntity.date = uploadedDocument.date;
        documentTypeEntity.file = uploadedDocument.file;
        documentTypeEntity.settingDocumentType =
          uploadedDocument.settingDocumentType;
        documentTypeEntity.mandatory = uploadedDocument.mandatory;
        documentTypeEntity.documentIndex = uploadedDocument.documentIndex;
        documentTypeEntity.idfile = '';
        documentTypeEntity.idDocument = null;
        documentTypeEntity.exist = false;
        documentTypeEntity.password = uploadedDocument.password;
        this.costumerDocuments.push(documentTypeEntity);
      }
    }
    if (uploadedDocument.category === AcmConstants.LOAN) {
      let foundLoan = false;
      this.loanDocuments.map((value) => {
        if (
          value.title === uploadedDocument.title &&
          value.documentIndex === uploadedDocument.documentIndex
        ) {
          value.name = uploadedDocument.name;
          value.date = uploadedDocument.date;
          value.file = uploadedDocument.file;
          value.settingDocumentType = uploadedDocument.settingDocumentType;
          value.mandatory = uploadedDocument.mandatory;
          value.idfile = '';
          value.idDocument = null;
          value.exist = false;
          value.password = uploadedDocument.password;
          foundLoan = true;
        }
      });
      if (!foundLoan) {
        const documentTypeEntity: DocumentTypeEntity = new DocumentTypeEntity();
        documentTypeEntity.title = uploadedDocument.title;
        documentTypeEntity.name = uploadedDocument.name;
        documentTypeEntity.date = uploadedDocument.date;
        documentTypeEntity.file = uploadedDocument.file;
        documentTypeEntity.settingDocumentType =
          uploadedDocument.settingDocumentType;
        documentTypeEntity.mandatory = uploadedDocument.mandatory;
        documentTypeEntity.documentIndex = uploadedDocument.documentIndex;
        documentTypeEntity.idfile = '';
        documentTypeEntity.idDocument = null;
        documentTypeEntity.exist = false;
        documentTypeEntity.password = uploadedDocument.password;
        this.loanDocuments.push(documentTypeEntity);
      }
    }
    this.check();
  }

  docsChecker(): boolean {
    let x = this.documents.some((res) => res.file == null || res.file == undefined);
    return !x;
  }

  confirm() {
    let loan = this.sharedService.getLoanDeal();
    loan.stepPath = PathConstants.PATH_SUMMARY_INIT_APPROVAL;
    loan.actionToAcm = 'APPROVE';
    this.loanService.updateLoan(loan).subscribe((res) => {
      this.sharedService.setLoanDeal(res);
      this.router.navigate(['/' + PathConstants.PATH_SUMMARY_INIT_APPROVAL]);
    });
    this.onsave();
  }

  onsave() {
    if (this.allDocuments === undefined || this.allDocuments.length === 0) {
      this.sharedService.showInfo(this.translate.instant('alert.no_document_to_save'))
    } else {
      this.save();
    }
  }
  async save() {
    // disabled deleted documents
    if (this.documentsToRemove.length !== 0) {
      this.documentsToRemove.forEach((value) => {
        this.documentService.disableDocument(value).toPromise().then((res) => {});
      });
    }
    // save added documents
    const arrayFile: any[] = [];
    const documents: any[] = [];
    await this.allDocuments.map((value, index) => {
      this.allDocuments.forEach((document) => {});
      const document: LoanDocumentEntity = new LoanDocumentEntity();
      let settingDocumentType: SettingDocumentTypeEntity;
      settingDocumentType = value.settingDocumentType;
      if (value.file !== '') {
        document.titre = value.title;
        if (document.description) {
          document.description = value.description;
        } else {
          document.description = value.settingDocumentType.description;
        }
        document.auteur = AcmConstants.AUTEUR;
        document.loanId = this.loan.idIbLoan;
        document.settingDocumentTypeDTO = settingDocumentType;
        document.idCustomer = this.customer.ibCustomerId;
        document.customerName = this.sharedService.getCustomerName(this.customer);
        document.accountNumberExtern = this.loan.accountNumber;
        document.mandatory = value.mandatory;
        document.documentIndex = value.documentIndex;
        document.name = value.name;
        document.documentSize = value.file.size;
        document.password = value.password;

        arrayFile.push(value.file);

        if (value.exist === true) {
          document.name = value.name;
          this.documentService.createAcmDocument(document).subscribe((data) => {
            // call child function to change id saved document
            this.changeIdSavedDocument(document, data.idDocument);
          });
        } else {
          documents.push(document);
        }
      }
    });
    if (documents.length > 0) {
      await this.documentService
        .saveListDocuments(arrayFile, documents)
        .subscribe((value1) => {
          // call child function to change id saved document
          value1.forEach((doc) => {
            this.changeIdSavedDocument(doc, doc.idDocument);
          });
        });
    }
  }

  changeIdSavedDocument(document, idDocument) {
    if (document.settingDocumentTypeDTO.categorie === 1) {
      this.costumerDocuments.map((customerDoc) => {
        if (
          customerDoc.title === document.titre &&
          customerDoc.documentIndex === customerDoc.documentIndex
        ) {
          customerDoc.idDocument = idDocument;
        }
      });
    } else if (document.settingDocumentTypeDTO.categorie === 0) {
      this.loanDocuments.map((loanDoc) => {
        if (
          loanDoc.title === document.titre &&
          loanDoc.documentIndex === loanDoc.documentIndex
        ) {
          loanDoc.idDocument = idDocument;
        }
      });
    }
    this.allDocuments = [];
  }

  backnav() {
    window.history.back();
    return false;
  }

  sendOTP() {
    this.sendOTPClicked.emit();
  }

  increaseZoom() {
    this.zoomLevel += 0.1;
  }

  decreaseZoom() {
    this.zoomLevel = Math.max(0.1, this.zoomLevel - 0.1);
  }

}
