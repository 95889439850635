import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';
import { BankInformation } from 'src/app/shared/Entities/BankInformation.entity';
import { RequestTarabout } from 'src/app/shared/Entities/RequestValidatorTarabet.entity';
import { SettingDataStorageIbEntity } from 'src/app/shared/Entities/SettingDataStorageIb.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bank-information',
  templateUrl: './bank-information.component.html',
  styleUrls: ['./bank-information.component.scss']
})

export class BankInformationComponent implements OnInit {

  public mode : string;
  public bankForm : FormGroup;
  public attachIbanCertif : boolean = false;
  public lang: string;
  public banks : SettingDataStorageIbEntity[] = [];
  public selectedBank : SettingDataStorageIbEntity = new SettingDataStorageIbEntity;
  public ibanNumber : string='';
  public errBankName : string = '';
  public errIban : string = '';
  public customer : CustomerEntity;
  public showIbanInfo : boolean = false;
  public ibanInfo : string;
  public loan : LoanEntityIB;
  public bankInformation : BankInformation;
  public title : string;
  public url : string;

  constructor(private thirdPartyService : ThirdPartyService,public sharedService : SharedService, private loanService : LoanService,private router : Router,
              private paramService : ParametrageService,private route: ActivatedRoute, private translate : TranslateService, private customerService : CustomerService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {this.mode = params['mode'] });
    this.title = this.mode === 'update-customer' ? 'TITLE.bank_information' : 'TITLE.loan_application';
    this.url = this.mode === 'update-customer' ? '/personal-information' : '/home';
    this.customer = this.sharedService.getCustomer();
    this.lang = localStorage.getItem('lang');
    this.paramService.findAllDataStorageIb().subscribe((res) => {
      res.forEach((data) => {
        switch (data.category) {
          case 'BANK_INFORMATION':
            this.banks.push((data));
            break;
        }
      });
      this.loadBankInformations();
      if (this.mode !=='update' && this.mode !=='update-customer'){
        this.onBankSelectionChange();
      }
    })
  }

  loadBankInformations(){
    if (this.mode==='update'){
      this.loan = this.sharedService.getLoanDeal();
      const bankInformationString = this.loan.bankInformation;
      this.bankInformation = bankInformationString ? JSON.parse(bankInformationString) :null ;
    }
    else if(this.mode==='update-customer'){
      this.bankInformation = this.sharedService.getBankInformation();
    }
    else {
      let customer = this.sharedService.getCustomer();
      const otherInformationString = customer.otherInformations;
      const otherInformationObject = JSON.parse(otherInformationString);
      if(otherInformationObject){
        this.bankInformation = otherInformationObject.bankInformation ? otherInformationObject.bankInformation : null;
      }
    }
    this.ibanNumber = this.bankInformation?.iban.slice(2);
    this.selectedBank = this.banks.filter(bank => bank.libelle === this.bankInformation?.bankName )[0];
  }

  onBankSelectionChange() {
    if (this.selectedBank.description){
      this.ibanNumber= this.ibanNumber ? this.ibanNumber : "";
      this.showIbanInfo = true;
      this.ibanInfo = "iban number format must be : "  ;
    }
  }
  //select bank based on iban number
  onIbanNumberChange(){
    if (this.ibanNumber.length >= 6 ) {
      const code = this.ibanNumber.substring(2,4);
      const bank = this.banks.find(bank => bank.description.includes(code));
      if (bank){
       this.selectedBank = bank;
      }
    }
  }

  async attachIbanCertificate(){
    /** FOrm Verification Before passing */
    if (this.selectedBank && this.ibanNumber && this.ibanNumber.length == 22 && this.checkIbanFormat()){
     await this.confirm();
    } else if (!this.selectedBank){
      this.sharedService.showError(this.translate.instant('ERR.select_bank_required'))
    } else if (!this.ibanNumber) {
      this.sharedService.showError(this.translate.instant('ERR.iban_number_required'))
    } else if (this.ibanNumber.length !=22){
      this.sharedService.showError(this.translate.instant('ERR.iban_number_length'))
    }
  }

  checkIbanFormat(){
    const code = this.ibanNumber.substring(2,4);
    if (this.selectedBank.description.includes(code)){
      return true;
    }
    else {
      this.sharedService.showError(this.translate.instant('ERR.incorrect_iban_format'))
      return false;
    }
  }

  /** Update loan and launch it in acm by action */
 async confirm() {
    this.errBankName = '';
    this.errIban = '';
    if (this.selectedBank && this.ibanNumber){
      let loan = this.sharedService.getLoanDeal();
      let customer = this.sharedService.getCustomer();
      let bankInformation  = {bankName : this.selectedBank.libelle,bankDescription : this.selectedBank.libelle,bankCode : this.selectedBank.code ,iban : "SA"+this.ibanNumber};
      let existingCustomerInfo = customer.otherInformations ? JSON.parse(customer.otherInformations) : {};
      existingCustomerInfo.bankInformation = bankInformation
      customer.otherInformations = JSON.stringify(existingCustomerInfo)
      if (this.mode !=='update-customer'){
      loan.bankInformation = JSON.stringify(bankInformation);
      loan.stepPath = PathConstants.PATH_SUMMARY_INIT_APPROVAL;
      loan.actionToAcm = null;
      const numIban = "SA"+this.ibanNumber;
      let requestAccountVerifierTarabut = new RequestTarabout();
      requestAccountVerifierTarabut.iban = numIban;
      // Valid National ID For Iban
      if (environment.production){
        requestAccountVerifierTarabut.identifier = this.customer.identity;
      }else{
        requestAccountVerifierTarabut.identifier = "1098184854";
      }
      // inValid National ID For Iban
      await this.thirdPartyService.accountTarabutVerifier(requestAccountVerifierTarabut).toPromise().then((data)=>{
        if( data.result === 'VERIFIED' ){
      this.loanService.updateLoan(loan).toPromise().then(res => {
        this.customerService.updateCustomer(customer).subscribe((data)=>{
          this.sharedService.showSuccess(this.translate.instant( 'INFO.iban_update_succesfully'))
          this.sharedService.setCustomer(data);
          this.sharedService.setLoanDeal(res);
          this.router.navigate(['/' + PathConstants.PATH_SUMMARY_INIT_APPROVAL]);
        })
       });
        } else {
         this.sharedService.showError(this.translate.instant( 'ERR.iban_number_wrong'))
        }
       }, (error => {
        this.sharedService.showError(this.translate.instant( 'ERR.iban_number_wrong'))
       }));
    }
    else {
      // update the customer other info
      this.customerService.updateCustomer(customer).subscribe((res)=>{
        this.sharedService.setCustomer(customer);
        this.router.navigate(['/' + PathConstants.PATH_PERSONAL_INFORMATION]);
      })
    }
    }
    }

}
