import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/ib-services/shared.service';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss']
})
export class FooterMenuComponent implements OnInit {

  public lang : string;

  constructor(private sharedService:SharedService) { }

  ngOnInit() {
    this.lang = this.sharedService.getLang();
  }

}
