<div class="bg-2 vh-100 vw-100 ">
  <app-screen-title [title]="'payment_mode'" [color]="'color1'" [backTo]="'/loan-schedule-details'"></app-screen-title>

  <div class="payment-no-refresh mb-2 py-4" *ngIf="!checkoutResponse">
    <div class="payment-loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <h2>{{'INFO.Generating_Payment_Form' |translate}}</h2>
  </div>

  <div id="hyperpay-widget-container" style="background : #f6f8ff">
  <form [action]="statusPaymentPage"
  class="paymentWidgets"
  data-brands="MADA MASTER VISA"></form>
  </div>


</div>

<app-footer-menu></app-footer-menu>


