import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { ProductEntity } from 'src/app/shared/Entities/product.entity copy';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { CalculationType } from 'src/app/shared/mb-constants/ib_constants';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-simulate-loan',
  templateUrl: './simulate-loan.component.html',
  styleUrls: ['./simulate-loan.component.scss'],
})
export class SimulateLoanComponent implements OnInit {
  public salaryDetailsEntry: boolean = true;
  public simulator: boolean = false;
  public loanDetailsForm: FormGroup;
  public user: UserEntity | undefined;
  public customer: CustomerEntity;
  public max = 50000;
  public min = 0;
  public value = 0;
  public minTerm = 1;
  public maxTerm = 15;
  public term = 1;
  public maxInstallment: number;
  public maxAmountGeneral: number;
  public repaymentMonthly = 0;
  public interestAmount: number;
  public product = new ProductEntity();
  public lang: string;
  public submitted: boolean = false;
  public calculationType: string;

  constructor(
    private fb: FormBuilder,
    private paramService: ParametrageService,
    public sharedService: SharedService,
    public translate: TranslateService,
    private parametrageService: ParametrageService,
    public router: Router,
    private customerService: CustomerService
  ) {}

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    this.user = this.sharedService.getUser();
    this.loanDetailsForm = this.fb.group({
      currentSalary: ['', Validators.required],
      totalCharge: ['', Validators.required],
      commitment: [false],
    });
    if (this.user.customerId) {
      this.customerService
        .getCustomerById(this.user.customerId)
        .subscribe((res) => {
          this.customer = res;
          this.sharedService.setCustomer(this.customer);
        });
    }

    if (!this.sharedService.getProduct()) {
      let product = new ProductEntity();
      this.parametrageService.getProducts(product).subscribe((res) => {
        this.sharedService.setProduct(res[0]);
      });
    }

    /** Retrieve Calculation Type */
    this.paramService
      .getEnvirementValueByKey('INSTALLMENT_CALCULATE_TYPE')
      .subscribe((res) => {
        this.calculationType = res.value;
      });
  }

  /** Tooltip Positioning */
  get tooltipPositionAmount() {
    const percentage = ((this.value - this.min) / (this.max - this.min)) * 100;
    return `calc(${percentage}% - 50%)`;
  }

  get tooltipPositionTerm() {
    const percentage =
      ((this.term - this.minTerm) / (this.maxTerm - this.minTerm)) * 100;
    return `calc(${percentage}% - 50%)`;
  }

  simulate() {
    this.submitted = true;
    if (this.loanDetailsForm.valid) {
      this.setMinAndMax();
      this.initMaxAmountCalculation();
      this.onInputChange();
      if (this.max >= 2000) {
        this.salaryDetailsEntry = false;
        this.simulator = true;
        this.submitted = false;
      } else {
        this.sharedService.showError(
          this.translate.instant('ERR.total_obligation_are_high')
        );
      }
    } else {
      this.sharedService.showInfo(
        this.translate.instant('ERR.information_not_valid')
      );
    }
  }
  setMinAndMax() {
    this.product = this.sharedService.getProduct();
    // this.minTerm = this.product.productDetailsDTOs[0].minimumTerm;
    this.minTerm = 3;
    this.maxTerm = this.product.productDetailsDTOs[0].maximumTerm;
    this.max = this.product.productDetailsDTOs[0].maximumAmount;
    // this.min = this.product.productDetailsDTOs[0].minimumAmount;
    this.min = 2000;
    let rate = this.loanDetailsForm.value.commitment ? 55 : 33;
    this.maxInstallment =
      (Number(this.loanDetailsForm.value.currentSalary) * rate) / 100 -
      Number(this.loanDetailsForm.value.totalCharge);
    this.term = this.maxTerm;
  }
  initMaxAmountCalculation() {
    this.maxAmountGeneral = this.maxInstallment * this.maxTerm;
    this.maxAmountChecker();
    this.value = this.max;
  }
  maxAmountChecker() {
    if (
      this.maxAmountGeneral < this.product.productDetailsDTOs[0].maximumAmount
    ) {
      this.max = Math.ceil(this.maxAmountGeneral);
    } else {
      this.max = Math.ceil(this.product.productDetailsDTOs[0].maximumAmount);
    }
  }
  updateValue(event: { value: any }) {
    this.value = event.value;
    this.onInputChange();
  }
  onInputChange() {
    if (
      this.term >= this.minTerm &&
      this.term <= this.maxTerm &&
      this.value >= this.min &&
      this.value <= this.max
    ) {
      if (this.calculationType == CalculationType.FLAT) {
        let frequencePaiement = 1;
        let deferredPeriod = 0;
        let frequenceInterest = 1;
        let flatRate =
          this.product.flatInterestRate > 1
            ? this.product.flatInterestRate / 100
            : this.product.flatInterestRate;
        let tauxInteretMensuel = (flatRate * frequenceInterest) / 12;
        let repayment = (
          (this.value *
            (tauxInteretMensuel + 1 / (this.term / frequencePaiement)) *
            (this.term / frequencePaiement)) /
          (this.term / frequencePaiement - deferredPeriod)
        ).toFixed(3);
        let totalAmount =
          parseFloat(repayment) /
          (((tauxInteretMensuel + 1 / (this.term / frequencePaiement)) *
            (this.term / frequencePaiement)) /
            (this.term / frequencePaiement - deferredPeriod));
        this.value = Math.round(totalAmount);
        this.interestAmount = Number(
          (
            parseFloat(repayment) *
              (this.term / frequencePaiement - deferredPeriod) -
            this.value
          ).toFixed(3)
        );
        this.repaymentMonthly = parseFloat(repayment);
        this.repaymentMonthly = Math.abs(this.repaymentMonthly);
      } else if (this.calculationType == CalculationType.RATE) {
        // let rate = this.product.flatInterestRate > 1 ? this.product.flatInterestRate/100 : this.product.flatInterestRate;
        let rate = this.product.rate;
        let tauxMensuel = rate / 12;
        let repayment = (
          (tauxMensuel * this.value) /
          (1 - Math.pow(1 + tauxMensuel, -this.term))
        ).toFixed(3);
        let totalAmount =
          parseFloat(repayment) *
          ((1 - Math.pow(1 + tauxMensuel, -this.term)) / tauxMensuel);
        this.value = Math.round(totalAmount);
        this.interestAmount = Number((this.value * tauxMensuel).toFixed(3));
        this.repaymentMonthly = parseFloat(repayment);
        this.repaymentMonthly = Math.abs(this.repaymentMonthly);
      }
    }
  }

   // Dynamically calculate markers for each range
   get markersAmount() {
    const markers = [];
    const step = 500; // Adjust step size as per your range
    for (let i = this.min; i <= this.max; i += step) {
      markers.push((i - this.min) / (this.max - this.min) * 100);
    }
    return markers;
  }

  get markersTerm() {
    const markers = [];
    const step = 1;  // For months, each step is 1
    for (let i = this.minTerm; i <= this.maxTerm; i += step) {
      markers.push((i - this.minTerm) / (this.maxTerm - this.minTerm) * 100);
    }
    return markers;
  }

  applyNow() {
    // if ((this.value >= this.min && this.value <= this.max) &&
    // (this.term >= this.minTerm && this.term <= this.maxTerm)) {
    //   let loan = new LoanEntityIB();
    // loan.applyAmountTotal = this.value;
    // loan.termPeriodNum = this.term;
    // loan.normalPayment= this.repaymentMonthly;
    // if (this.user.customerId){
    //   this.customer = this.sharedService.getCustomer();
    //   loan.customerDTO = this.customer;
    //   loan.customerId = this.customer.id;
    //   loan.customerName = this.customer.firstName;
    //   this.sharedService.setLoanDeal(loan);
    //   this.router.navigate(['/loan-registration']);
    // }
    // }
    // else if (this.value < this.min || this.value > this.max){
    //   this.sharedService.showError(this.translate.instant('ERR.amount_not_valid')) ;
    //  }
    //  else if (this.term < this.minTerm || this.term > this.maxTerm){
    //   this.sharedService.showError(this.translate.instant('ERR.term_not_valid')) ;
    //  }
    this.router.navigate(['/' + PathConstants.PATH_APPLICATION_TERMS]);
  }
}
