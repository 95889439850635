<div style="display: contents !important;">
  <div id="header-home" class="px-3 header-top-home" *ngIf="customer && mode != 'listLoans'" style="margin-top: 1rem;">
    <div class="fsb">
      <label class="p-4 white op-80"> <label class="label-main" class="op-50">{{'WELCOME' | translate}}</label><br>
        {{customer.firstName}}</label>
      <div class="notification-container">
        <img class="ib-icons m-2"
          style="margin-top: 2.2rem !important;width: 30px !important;filter: drop-shadow(2px 4px 6px black);"
          src="assets/images/icons/notif2.png" alt="notif2" routerLink="/notifications">
        <div class="notification-dot" *ngIf="nbNotifs != 0">{{nbNotifs}}</div>
      </div>
    </div>

    <div class="card card-bg-secondary px-2">

      <label class="text-center white op-50"> {{'INFO.budget_u_can_request' | translate}}</label>

      <div class="py-2">
        <legend class="text-center white" style="font-size:xxx-large;">{{maxAmountProduct | currency:' '}}
          <span class="fss" *ngIf="lang=='en'">{{'CURRENCY.sar' | translate}}</span>
          <img class="ib-icons m-2" *ngIf="lang=='ar'" src="assets/images/icons/sarAr.png" alt="sarAr">
        </legend>
      </div>

      <div class="flc py-2 mb-2" routerLink="/application-terms">
        <button type="button" class="btn btn-secondary px-4"><i class='bx bx-up-arrow-alt me-1 fsxl'
            style="transform: rotate(45deg)"></i> {{ 'BUTTONS.apply_now' | translate }} </button>
      </div>
    </div>
  </div>

  <div id="loan-applies-content"  style="overflow-y: auto;" [ngClass]="{'fixed-bottom card card-loan-process' : mode != 'listLoans' }">
    <div class="p-3 mb-5" *ngIf="loansList">
      <legend class=" me-5 mb-2 pb-2 fw-650  color1" *ngIf="mode != 'listLoans'">{{ 'LABELS.loans_applications' | translate}} <i
          class='bx bx-refresh' (click)="refreshListLoans()"></i></legend>

      <!-- IF NO LOANS APPLIED -->
      <div id="no-loans-found" *ngIf="loansList.length == 0 ">
        <div class="mt-4 pt-4 flc inline-flex align-items-center">
          <img class="img-logo-sanad op-20" style="width: 160px;" src="assets/images/money_calendar.png" alt="My Image">
        </div>
        <label class="label-info flc">{{'INFO.no_loans' | translate}}</label>
      </div>

      <!-- APPLICATION -->
      <div class="" *ngFor="let loan of loansList; let index = index">
        <div class="card px-3 py-2 mb-2">
          <div class="fsb " (click)="redirectLoan(index)" *ngIf="loan.accountNumber">
            <div class="d-grid" [ngClass]="{'red-dot': clickableSteps.includes(loan.statutLibelle)}">
              <!-- Loan Account Number with Loading Icon -->
              <label class="mb-1 color1 fw-700 mt-1" *ngIf="loan.accountNumber" aria-label="Loan Account Number">
                {{ loan.accountNumber }}
                <i class="bx bx-loader m-1 bx-loader-animated" *ngIf="loan.statutLibelle === 'Disburse'"></i>
              </label>

              <!-- Processing State -->
              <label class="mb-1 color1 fw-700 fss" style="color: lightgray" *ngIf="!loan.loanId || !loan.accountNumber"
                aria-label="Processing Information">
                {{ 'INFO.processing' | translate }}
                <i class="bx bx-loader m-1" *ngIf="loan.statutLibelle === 'Disburse'"></i>
              </label>

              <!-- Default Loan Status -->
              <label class="mb-1 fss op-50 fw-650" *ngIf="
                  !loan.statutLibelle?.includes('Simah') &&
                  loan.statutLibelle !== 'simah consent' &&
                  loan.statutLibelle !== 'Customer Information & Documents' &&
                  !loan.statutLibelle?.includes('Dakhli') &&
                  !loan.statutLibelle?.includes('Lynk')
                " aria-label="Default Loan Status">
                {{ getIfTranslationExist(loan.statutLibelle,loan.statut) }}
              </label>

              <!-- Customer Information Documents -->
              <label class="mb-1 fss op-50 fw-650" *ngIf="loan.statutLibelle === 'Customer Information & Documents'"
                aria-label="Customer Information Documents">
                {{ 'STATUT_LIBELLE.Customer Information Documents' | translate }}
              </label>

              <!-- Lynk Sell/Buy -->
              <label class="mb-1 fss op-50 fw-650" *ngIf="loan.statutLibelle?.includes('Lynk')"
                aria-label="Lynk Sell or Buy">
                {{ 'STATUT_LIBELLE.Lynk Sell/Buy' | translate }}
              </label>

              <!-- Dakhli Salary -->
              <label class="mb-1 fss op-50 fw-650" *ngIf="loan.statutLibelle?.includes('Dakhli')"
                aria-label="Get Salary from Dakhli">
                {{ 'STATUT_LIBELLE.get_salary_from_dakhli' | translate }}
              </label>

              <!-- Simah Consent -->
              <label class="mb-1 fss op-50 fw-650" *ngIf="
                  loan.statutLibelle === 'Simah consent' ||
                  loan.statutLibelle === 'Check Simah' ||
                  loan.statutLibelle === 'simah consent'
                " aria-label="Simah Consent Status">
                {{ 'STATUSES.pending' | translate }}
              </label>
            </div>



            <button class="btn btn-status-pending   login-btn"
              *ngIf="loan.statut != '6' && loan.statut != '8' && loan.statutLibelle != 'Disburse' && loan.statut != '5'">{{'STATUSES.pending'
              | translate}}</button>
            <button class="btn btn-status-pending   login-btn"
              *ngIf="loan.statutLibelle == 'Disburse'">{{'STATUSES.pending' | translate}}</button>
            <button class="btn btn-status-rejected   login-btn"
              *ngIf="loan.statut == '5' || loan.statut == '6'">{{'STATUSES.rejected' | translate}}</button>
            <button class="btn btn-status-success   login-btn" *ngIf="loan.statut == '8'">{{'STATUSES.approved' |
              translate}}</button>

          </div>

          <div class="fsb " (click)="showPopupNafedh(index)" *ngIf="!loan.accountNumber ||  loan.accountNumber == null">
            <div class="d-grid ">
              <label class=" my-2 color1 fw-700 fsm op-80">
                {{'INFO.loan_process_nafedh' | translate}}<i class='bx bx-loader m-1'></i>
              </label>
            </div>

            <button class="btn btn-status-pending   login-btn"
              style="background-color: #11998e !important;color:white !important">
              {{loan.nafedhCode}}
            </button>
          </div>
        </div>

      </div>
    </div>

  </div>


</div>
<app-footer-menu *ngIf="this.sharedService.getUser().login != undefined && mode != 'listLoans'"></app-footer-menu>

<!-- EVALUATION POPUP  -->
<app-popup *ngIf="showPopupEval" [source]="'EVALUATION'" (closePopup)="handleClosePopup()"></app-popup>
