<div class=" vw-100 ">

  <!-- Information Salary Entry -->
  <div id="new-pw-div" class="bg-2 vh-100" *ngIf="salaryDetailsEntry && !simulator">
    <app-screen-title [title]="'TITLE.Salary_calculator'" [color]="'color1'"></app-screen-title>
    <form [formGroup]="loanDetailsForm" (ngSubmit)="simulate()">
      <div class="px-4 mt-4">
        <div class="mb-4">
          <div class="mb-2">
            <label class="form-label ms-2 fw-500 fsl">{{'LABELS.total_salary' | translate}}
            </label>
            <div style="position: relative;">
              <input type="number" class="form-control"
                placeholder="{{'PLACEHOLDER.enter_your_total_salary' | translate}}" formControlName="currentSalary"
                [ngClass]="{'fc-error': loanDetailsForm.controls['currentSalary'].invalid && submitted}">
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label ms-2 fw-500 fsl">{{ 'LABELS.total_monthly_obligations' | translate}}
            </label>
            <div style="position: relative;">
              <input type="number" class="form-control"
                placeholder="{{'PLACEHOLDER.enter_total_monthly_obligations' | translate}}"
                formControlName="totalCharge"
                [ngClass]="{'fc-error': loanDetailsForm.controls['totalCharge'].invalid && submitted}">
            </div>
          </div>
        </div>
      </div>
      <div class="text-center px-3">
        <button type="submit" class="btn btn-primary btn-lg mb-2 login-btn">{{'BUTTONS.calculate' | translate}}
        </button>
      </div>
    </form>
  </div>

  <!-- Simulator SANAD -->
  <div *ngIf="!salaryDetailsEntry && simulator">
    <!-- App Header Title Page -->

    <div style="overflow: unset !important;">
      <div id="app-screen-title">
        <div class="flc align-items-center p-3 pt-5 pb-4">
          <div class="back-div" style="background-color:#30449E">
            <i class='bx bx-arrow-back m-1 fsxl' [style]="{'color':'white'}"
              (click)="salaryDetailsEntry = true;simulator = false;"
              [ngStyle]="{'transform': (lang === 'ar' || lang == null) ? 'rotate(180deg)' : 'none'}"></i>
          </div>
          <legend class="text-center fw-650" [style]="{'color':'white'}">{{'TITLE.calculator' | translate}}</legend>
        </div>
      </div>
      <!-- Card Header -->
      <div class="card card-bg-secondary mx-3 mt-3">
        <label class="label-info-secondary text-center op-50 white"> {{'INFO.maximum_amount' | translate}}</label>
        <div class="">
          <legend class="text-center white fsxl">{{max | currency:' '}}
            <span class="fss" *ngIf="lang=='en'">{{'CURRENCY.sar' | translate}}</span>
            <img class="ib-icons m-2" *ngIf="lang=='ar'" src="assets/images/icons/sarAr.png" alt="sarAr">
          </legend>
        </div>
      </div>
    </div>


    <div class="px-3">
      <!-- Simulator -->
      <div class="bg-2 vw-100">
        <div id="login" class="card presimulator-card  fixed-bottom p-3 pb-5 mb-5 "
          style="overflow-x: hidden !important;">

          <div class="mb-2" style="padding: 0px 22px 0px 22px">
            <div id="tooltip_amout_value">
              <div *ngIf="lang=='ar'" class="custom-tooltip" [style.right]="tooltipPositionAmount">
                <p style="background-color: #3c63e2;" class="white">{{ value }} {{ 'CURRENCY.sar' | translate }}</p>
              </div>
              <div *ngIf="lang=='en'" class="custom-tooltip" [style.left]="tooltipPositionAmount">
                <p style="background-color: #3c63e2;" class="white">{{ value }} {{ 'CURRENCY.sar' | translate }}</p>
              </div>
            </div>
            <label class="form-label ms-2">{{ 'LABELS.select_amount' | translate }}</label>
            <div style="position: relative;">
              <input type="range" class="form-range" [max]="max" [min]="min" step="500" id="customRange1" value="235"
                (change)="onInputChange()" [(ngModel)]="value">
            </div>
            <div class="mb-2 fsb">
              <label class="form-label ms-2 pt-2 fw-650 op-50">{{ 'LABELS.amount' | translate }}</label>
              <div style="position: relative;">
                <label class="label-info" style="position: absolute;margin: 5px !important ;"> {{ 'CURRENCY.sar' | translate }} </label>
                <input type="number" class="form-control input-number" style="padding: 7px !important" [(ngModel)]="value" (ngModelChange)="onInputChange()">
              </div>
            </div>
          </div>

          <div class="mb-2" style="padding: 0px 22px 0px 22px">
            <div id="tooltip_term_value">
              <div *ngIf="lang=='ar'" class="custom-tooltip" [style.right]="tooltipPositionTerm">
                <p style="background-color: #3c63e2;" class="white">{{ term }}</p>
              </div>
              <div *ngIf="lang=='en'" class="custom-tooltip" [style.left]="tooltipPositionTerm">
                <p style="background-color: #3c63e2;" class="white">{{ term }}</p>
              </div>
            </div>
            <label class="form-label ms-2">{{ 'LABELS.select_budget' | translate }}</label>
            <div style="position: relative;">
              <input type="range" class="form-range" [max]="maxTerm" [min]="minTerm" step="1" id="customRange2" value="10"
                [(ngModel)]="term" (ngModelChange)="onInputChange()">
            </div>
            <div class="mb-2 fsb">
              <label class="form-label ms-2 pt-2 fw-650 op-50">{{ 'LABELS.term' | translate }}</label>
              <div style="position: relative;">
                <label class="label-info" style="position: absolute;margin: 5px !important ;"> {{ 'LABELS.months' | translate }} </label>
                <input type="number" class="form-control input-number" style="padding: 7px !important" [(ngModel)]="term" (ngModelChange)="onInputChange()">
              </div>
            </div>
          </div>


          <div class="px-2 mt-2 mb-4" style="display: flex; align-items: center;gap: 5px; color: #dc3545;">
            <i class='bx bx-info-circle' style="font-size: 20px;"></i>
            <span class="fss fw-600">{{ 'numbers_shown_are_approximate' |translate}}</span>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="text-center">
                  <img class="ib-icons " src="assets/images/icons/money_icon.png" alt="money_icon"
                    style="width:30px !important">
                  <label class="form-label ms-2 p-2">{{'LABELS.monthly_installment'|translate}}</label>
                </div>
                <div class="text-center">
                  <label class="form-label ms-2 p-2 fw-650 text-primary fsl">{{repaymentMonthly | currency:' '}} <span
                      class="fss">{{'CURRENCY.sar' | translate}}</span></label>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="text-center">
                  <img class="ib-icons" src="assets/images/icons/money_round.png" alt="money_round">
                  <label class="form-label ms-2 p-2 "> {{'LABELS.total' | translate}}</label>
                </div>
                <div class="text-center">
                  <label class="form-label ms-2 p-2 fw-650 text-primary fsl">{{repaymentMonthly * term | currency:' '}}
                    <span class="fss">{{'CURRENCY.sar' | translate}}</span> </label>
                </div>

              </div>
            </div>
          </div>
          <div class="text-center pt-4">
            <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="applyNow()">
              {{'BUTTONS.apply_now' | translate}}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
<app-footer-menu></app-footer-menu>
