import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDigitLimit]'
})
export class DigitLimitDirective {
  private readonly maxDigits = 10;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: InputEvent): void {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');
    if (value.length > this.maxDigits) {
      value = value.slice(0, this.maxDigits);
    }
    input.value = value;
  }
}
