import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IbNotification } from '../shared/Entities/ibNotification.entity';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {PushNotifications,PushNotificationSchema,Token,ActionPerformed} from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root'
})
export class IbNotificationServiceService {

constructor(public httpClient: HttpClient) { }

  launchNotification(ibNotification : IbNotification): Observable<Boolean> {
    return this.httpClient.post<Boolean>(environment.oauthUrl + 'ib-notifications/launch-notification', ibNotification);
  }

  getNotificationByCustomerId(customerId : number): Observable<IbNotification[]> {
    return this.httpClient.get<IbNotification[]>(environment.oauthUrl + 'ib-notifications/find-notifications/' + customerId );
  }

  getNotificationsCount(customerId : number): Observable<number> {
    return this.httpClient.get<number>(environment.oauthUrl + 'ib-notifications/count-notifications/' + customerId );
  }

  updateSeenNotification(ibNotification : IbNotification): Observable<IbNotification> {
    return this.httpClient.post<IbNotification>(environment.oauthUrl + 'ib-notifications/update-seen-notification/', ibNotification );
  }

  initPushNotifications() {
    // Request permission
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register for push notifications
        PushNotifications.register();
      }
    });

    // Handle token
    PushNotifications.addListener('registration', (token: Token) => {
      console.log('Push registration success, token: ' + token.value);
    });

    // Handle error
    PushNotifications.addListener('registrationError', (error: any) => {
      console.error('Error on registration: ' + JSON.stringify(error));
    });

    // Handle notification received
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Notification received: ', notification);
      }
    );

    // Handle notification action
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Notification action performed', notification);
      }
    );
  }

}
