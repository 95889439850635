<div class="bg-nafedh vh-100 vw-100 p-3" style="height: 100vh !important;" >
  <div class="mt-4" style="border-radius: 50%;padding: 5px;background-color:#0E8776;position: absolute;">
    <i class='bx bx-arrow-back m-1 fsxl' style="color: #B4CCC3;" routerLink="/application-terms" [ngStyle]="{'transform': (lang === 'ar' || lang == null) ? 'rotate(180deg)' : 'none'}"></i>
  </div>
  <div class=" p-5 mt-4 flc">
    <img style="width: 160px;" src="assets/images/nafedh_logo.png" alt="nafedh_logo">
  </div>


  <div class="card ib_card  fixed-bottom">
    <div class="card-header">
     <label class="label-main flc fw-650 color5">{{'HEADER.nafedh_login'|translate}}</label>
    </div>
    <div class="card-body" style="overflow-y: auto;">
      <!-- Nafedh Login -->
      <div id="nafedh_login" *ngIf="!showNafedhOtp">
        <form [formGroup]="nafedhForm">
          <div class="mb-2">
            <label class="form-label ms-2" for="nationalId">{{ 'LABELS.national_id_iqama' | translate}}</label>
            <div style="position: relative;">
              <input type="tel" class="form-control" placeholder="{{'PLACEHOLDER.enter_nid' | translate}}" formControlName="nationalId" appDigitLimit [ngClass]="{'rtl-input': lang==='ar'}">
            </div>
          </div>
          <div class="mb-2">
            <div class="fsb">
              <label class="form-label ms-2" for="mobileNumber">{{ 'LABELS.phone_number' | translate}} <span
                class="ms-1 red">*</span></label>
                <label class="form-label ms-2  text-secondary fss">{{'LABELS.phone_number_registered_in_absher' | translate}}</label>
            </div>
            <div class=""  style="position: relative;">
              <span class="m-2 p-2 mobile-span" *ngIf=" sharedService.getLang() != 'ar' " >+ 966</span>
              <span class="m-2 p-2 mobile-span" *ngIf=" sharedService.getLang() == 'ar' ">966 +</span>
              <input type="number" class="form-control mobile-number-input" placeholder="5 XXXX XXXX" dir="ltr" aria-label="phone_number" formControlName="mobileNumber"
                aria-describedby="basic-addon1" (input)="checklength($event)">
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label ms-2" for="dateOfBirth">{{ 'LABELS.date_of_birth' | translate}}</label>
            <div style="position: relative;">
              <img class="ib-icons m-2 pt-2" src="assets/images/icons/calendar-icon.png" alt="calendar-icon" style="position: absolute;">
              <input type="date" class="form-control text-center" formControlName="dateOfBirth">
            </div>
          </div>
          <div class="d-flex mt-4 mb-3">
            <input type="checkbox" id="terms-conditions" class="m-1" formControlName="isCheckedTerms">
            <label for="myCheckbox" class="label-main fw-650 fss color1" for="isCheckedTerms" (click)="openModalTerms()">
              {{'INFO_terms_agreements' | translate}}
            </label>
          </div>
        </form>
        <div class="text-center">
          <button (click)="next()" type="button" class="btn btn-primary btn-lg mb-2 login-btn"
          [disabled]="!this.nafedhForm.controls['isCheckedTerms'].value" >
            {{'BUTTONS.next' | translate}}
          </button>
        </div>
      </div>

      <!-- Nafedh OTP -->
      <div id="nafedh_OTP" *ngIf="showNafedhOtp" class="text-center">
        <label class="label-main fw-600 color1 fsl" style="display: block;"> {{'OTHER.nafedh_code' |translate}} </label>
        <label class="label-info fw-600 fsm mt-4" style="color: darkslategray;"> {{'OTHER.got_to_nafedh_to_get_code' |translate}} </label>
        <legend class=" fw-650 flc op-70" style="font-size: xxx-large;"> {{nafedhCode}} </legend>

        <div class="payment-no-refresh">
          <div class="payment-loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <h2>{{'INFO.waiting_for_response' |translate}}</h2>
        </div>

        <div class="text-center mb-2 fixed-bottom px-2">
          <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="launchNafedhApp()" *ngIf="!showHomeButton">
            {{'BUTTONS.go_to_nafath' | translate}}
          </button>
          <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" routerLink="/home" *ngIf="showHomeButton">
            {{'BUTTONS.home' | translate}}
          </button>
        </div>
        <div class="text-center pt-5">
        </div>
        <div style="margin: 10rem !important;"></div>
      </div>

    </div>
  </div>

</div>

<!-- Dialog Terms & Agreements-->
<div id="modal-dialog" class="modal_term" style="zoom:unset !important">
  <p-dialog [(visible)]="showModalTerms" [breakpoints]="{'80vh': '85vw'}" [style]="styleDialog" [closable]="false"
    [modal]="true" overlay="true">
    <div class="p-dialog-content" style="text-align: justify;">
      <h3 class="mb-4 mt-2 flc color1 fw-500">{{'INFO.terms_conditions' | translate}}</h3>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.first_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.terms_lecture' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.second_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.definitions' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.third_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.needs_subscription' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.fourth_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.security_usecases' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.fifth_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.security_confidentiality' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.sixth_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.disclaimer_compensation' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.seventh_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.terms_changes' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.eighth_part' |translate}}
      </span>

      <legend class="fss fw-600 black"> {{'INFO.check_payment_society'| translate}}</legend>
      <a class="u fw-600 fss flc"> {{'LINK.rules_protection' | translate}}</a>

      <li><a class="u fw-600 fss">{{'TERMS.followed_law' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.nineth_part' |translate}}
      </span>

      <li><a class="u fw-600 fss">{{'TERMS.agent_acceptance' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.tenth_part' |translate}}
      </span>

      <div class="row">
        <div class="flc p-3">
          <button (click)="confirmTerms()" class="btn-pill btn btn-sm btn-outline-primary cf-hover-btn color1">
            <i class='bx bx-check-square'></i> {{'BUTTONS.confirm_term' | translate}}
          </button>
        </div>
      </div>
    </div>

  </p-dialog>
</div>

