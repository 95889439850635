import { AbstractControl, ValidatorFn } from "@angular/forms";


 /** Internet Banking Evaluation Constants */
export class EvalConstants {
  public static readonly COMPLETELY_HAPPY = 'COMPLETELY_HAPPY';
  public static readonly HAPPY = 'HAPPY';
  public static readonly NEUTRAL = 'NEUTRAL';
  public static readonly DISSATISFIED = 'DISSATISFIED';
  public static readonly COMPLETELY_DISSATISFIED = 'COMPLETELY_DISSATISFIED';
}

export class CalculationType {
  public static readonly FLAT = 'FLAT';
  public static readonly RATE = 'RATE';
}

export class DeleteAccountMotifs{
  public static readonly EXCESSIVE_FEES = 'Excessive fees';
  public static readonly INACTIVE_ACCOUNT = 'Poor customer service';
  public static readonly POOR_CU_SERVICE = 'Inactive account';
}

export class DeleteAccountMotifsDescription{
  public static readonly EXCESSIVE_FEES_DESC = 'The monthly maintenance fees or overdraft fees are too high.';
  public static readonly INACTIVE_ACCOUNT_DESC = 'I no longer use this account and want to close it.';
  public static readonly POOR_CU_SERVICE_DESC = 'Unsatisfactory experiences with customer support.';
}

export const listMotifs = [DeleteAccountMotifs.EXCESSIVE_FEES,DeleteAccountMotifs.INACTIVE_ACCOUNT,DeleteAccountMotifs.POOR_CU_SERVICE];
export const listMotifDesciptions = [DeleteAccountMotifsDescription.EXCESSIVE_FEES_DESC,DeleteAccountMotifsDescription.INACTIVE_ACCOUNT_DESC,DeleteAccountMotifsDescription.POOR_CU_SERVICE_DESC];


export function maxLengthNumberValidator(maxLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value ? control.value.toString() : '';
    return value.length > maxLength ? { 'maxLength': { value: control.value } } : null;
  };
}
