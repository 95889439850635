<div class="fixed-bottom fsxl footer-ib">
  <div class="card" style="filter: drop-shadow(2px 4px 6px lightgray);border-radius: 0;">
    <ul class="nav nav-pills nav-justified">
      <li class="nav-item" >
        <a class="nav-link py-2" routerLinkActive="active" routerLink="/home"
          [ngClass]="{'radius-left': lang === 'en', 'radius-right': lang === 'ar'}">
          <span>
            <img class="ib-icons m-2" src="assets/images/icons/home.png" alt="biomet" style="width:25px !important">
          </span>
          <span class="nav-text fss" >{{ 'FOOTER_MENU.home' | translate }}</span>
        </a>
      </li>

      <li class="nav-item" >
        <a class="nav-link py-2 disable-radius" routerLinkActive="active" routerLink="/simulate">
          <span>
            <img class="ib-icons m-2" src="assets/images/icons/calculator_disabled.png" alt="biomet"
              style="width:25px !important;">
          </span>
          <span class="nav-text fss">{{ 'FOOTER_MENU.calculator' | translate }}</span>
        </a>
      </li>
      <li class="nav-item" >
        <a class="nav-link py-2 disable-radius" routerLinkActive="active" routerLink="/loan-applications">
          <span>
            <img class="ib-icons m-2" src="assets/images/icons/applies.png" alt="biomet" style="width:25px !important">
          </span>
          <span class="nav-text fss">{{ 'FOOTER_MENU.my_requests' | translate }}</span>
        </a>
      </li>
      <li class="nav-item" >
        <a class="nav-link py-2" routerLinkActive="active" routerLink="/settings"
          [ngClass]="{'radius-left': lang === 'en', 'radius-right': lang === 'ar'}">
          <span>
            <img class="ib-icons m-2" src="assets/images/icons/gear.png" alt="biomet" style="width:25px !important">
          </span>
          <span class="nav-text fss">{{ 'FOOTER_MENU.settings' | translate }}</span>
        </a>
      </li>
    </ul>

  </div>
</div>
