import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './main-components/auth-components/login/login.component';
import { ForgetPasswordComponent } from './main-components/auth-components/forget-password/forget-password.component';
import { ScreenTitleComponent } from './layouts-components/screen-title/screen-title.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient,HTTP_INTERCEPTORS,HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoaderComponent } from './layouts-components/loader/loader.component';
import { LoaderService } from './ib-services/loader.service';
import { LoaderInterceptor } from './layouts-components/loader/loader.interceptor';
import { OtpBiometricsValidationComponent } from './main-components/auth-components/otp-biometrics-validation/otp-biometrics-validation.component';
import { HomeComponent } from './main-components/ib-components/home/home.component';
import { FooterMenuComponent } from './layouts-components/footer-menu/footer-menu.component';
import { SimulateLoanComponent } from './main-components/ib-components/simulate-loan/simulate-loan.component';
import {MatSliderModule} from '@angular/material/slider';
import { SettingsComponent } from './main-components/ib-components/settings/settings.component';
import { AccountDetailsComponent } from './main-components/ib-components/settings/account-details/account-details.component';
import { PersonalInformationComponent } from './main-components/ib-components/settings/personal-information/personal-information.component';
import { EditPersonalInformationComponent } from './main-components/ib-components/settings/edit-personal-information/edit-personal-information.component';
import { EditWorkInformationComponent } from './main-components/ib-components/settings/editWorkInformation/editWorkInformation.component';
import { LoanApplicationsComponent } from './main-components/ib-components/loan-applications/loan-applications.component';
import { KnobModule } from 'primeng/knob';
import { LoanScheduleDetailsComponent } from './main-components/ib-components/loan-applications/loan-schedule-details/loan-schedule-details.component';
import { CommonModule, DatePipe } from '@angular/common';
import { AuthentificationInterceptor } from './ib-services/authentification.interceptor';
import { SharedService } from './ib-services/shared.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditIncomeDetailsComponent } from './main-components/ib-components/settings/edit-income-details/edit-income-details.component';
import { EditExpensesObligationsComponent } from './main-components/ib-components/settings/edit-expenses-obligations/edit-expenses-obligations.component';
import { ContactUsComponent } from './main-components/ib-components/settings/contact-us/contact-us.component';
import { FaqComponent } from './main-components/ib-components/settings/faq/faq.component';
import { AccordionModule } from 'primeng/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SchedulePaymentComponent } from './main-components/ib-components/loan-applications/schedule-payment/schedule-payment.component';
import { DocumentsComponent } from './main-components/ib-components/loan-applications/documents/documents.component';
import { ScheduleServicesComponent } from './main-components/ib-components/loan-applications/schedule-services/schedule-services.component';
import { ProductListComponent } from './main-components/ib-workflow-components/product-list/product-list.component';
import { ConsentValidationComponent } from './main-components/ib-workflow-components/consent-validation/consent-validation.component';
import { StepperComponent } from './layouts-components/stepper/stepper.component';
import { AboutSanadComponent } from './main-components/ib-components/settings/about-sanad/about-sanad.component';
import { NafedhComponent } from './main-components/auth-components/nafedh/nafedh.component';
import { InformationCustomerEntryComponent } from './main-components/ib-workflow-components/information-customer-entry/information-customer-entry.component';
import { BankInformationComponent } from './main-components/ib-workflow-components/bank-information/bank-information.component';
import { RequiredDocumentsComponent } from './main-components/ib-workflow-components/required-documents/required-documents.component';
import { SummaryInitApprovalComponent } from './main-components/ib-workflow-components/summary-init-approval/summary-init-approval.component';
import { PeriodSelectionComponent } from './main-components/ib-workflow-components/period-selection/period-selection.component';
import { SignContractComponent } from './main-components/ib-workflow-components/sign-contract/sign-contract.component';
import { ToastrModule } from 'ngx-toastr';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NewTransactionComponent } from './main-components/ib-components/loan-applications/new-transaction/new-transaction.component';
import { PaymentStatusComponent } from './main-components/ib-components/payment-status/payment-status.component';
import {DialogModule} from 'primeng/dialog';
import { ResetPasswordComponent } from './main-components/ib-components/settings/account-details/reset-password/reset-password.component';
import { PopupComponent } from './main-components/ib-components/popup/popup.component';
import { LoanStatusComponent } from './main-components/ib-workflow-components/loan-status/loan-status.component';
import { BiometricsSettingsComponent } from './main-components/ib-components/settings/biometrics-settings/biometrics-settings.component';
import { ChangeLanguageComponent } from './main-components/ib-components/settings/change-language/change-language.component';
import { NewStepperComponent } from './layouts-components/new-stepper/new-stepper.component';
import { AdvertisementComponent } from './advertising-pages/advertisement/advertisement.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationTermsComponent } from './main-components/ib-workflow-components/application-terms/application-terms.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { NotificationListComponent } from './main-components/auth-components/notification-list/notification-list.component';
import { WebsocketService } from './ib-services/Websocket.service';
import { LocalizedDatepickerComponent } from './shared/datepickerhijri/localized-datepicker/localized-datepicker.component';
import { DatepickerIslamicComponent } from './shared/datepickerhijri/localized-datepicker/datepicker-islamic/datepicker-islamic.component';
import { ClaimsComponent } from './main-components/ib-components/settings/claims/claims.component';
import { ViewClaimComponent } from './main-components/ib-components/settings/view-claim/view-claim.component';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { DeleteAccountComponent } from './main-components/auth-components/delete-account/delete-account.component';
import { DigitLimitDirective } from './directives/DigitLimit.directive';


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?');
}

@NgModule({
  declarations: [
    AppComponent,

    /** Layouts */
    ScreenTitleComponent,
    LoaderComponent,
    FooterMenuComponent,
    StepperComponent,
    NafedhComponent,
    LoanStatusComponent,
    AdvertisementComponent,

    /** Auth Components */
    LoginComponent,
    ForgetPasswordComponent,
    OtpBiometricsValidationComponent,
    BiometricsSettingsComponent,
    DeleteAccountComponent,

    /** IB Components */
    HomeComponent,
    SimulateLoanComponent,
    AccountDetailsComponent,
    LoanApplicationsComponent,
    LoanScheduleDetailsComponent,
    SchedulePaymentComponent,
    DocumentsComponent,
    ScheduleServicesComponent,
    ApplicationTermsComponent,

    /** Workflow Components */
    ProductListComponent,
    ConsentValidationComponent,
    InformationCustomerEntryComponent,
    BankInformationComponent,
    RequiredDocumentsComponent,
    SummaryInitApprovalComponent,
    PeriodSelectionComponent,
    SignContractComponent,

    /** HIJRI MODULE */
    LocalizedDatepickerComponent,
    DatepickerIslamicComponent,

    /** Settings Components */
    SettingsComponent,
    PersonalInformationComponent,
    EditPersonalInformationComponent,
    EditWorkInformationComponent,
    EditIncomeDetailsComponent,
    EditExpensesObligationsComponent,
    ContactUsComponent,
    FaqComponent,
    AboutSanadComponent,
    NewTransactionComponent,
    PaymentStatusComponent,
    ResetPasswordComponent,
    PopupComponent,
    ChangeLanguageComponent,
    NewStepperComponent,
    NotificationListComponent,
    ClaimsComponent,
    ViewClaimComponent,

    /** Directives */
    DigitLimitDirective

  ],
  imports: [
    NgOtpInputModule,
    NgbModule,
    MatSliderModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserModule,
    PdfViewerModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      // maxOpened: 1, => If We Want to open only one toast At a time
      // autoDismiss: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    /** PrimeNg Modules  */
    KnobModule,
    AccordionModule,
    DialogModule,
    NgbModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    WebsocketService,
    HTTP,
    DatePipe,
    SharedService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthentificationInterceptor,
      multi: true,
  },
  ],
  exports:[DigitLimitDirective],
  bootstrap: [AppComponent]
})
export class AppModule { }
