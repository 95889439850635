import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserEvaluation } from '../shared/Entities/UserEvaluation.entity';
import { AcmURLConstants } from '../shared/mb-constants/mb-url-constants';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {
    /**
     *
     * @param httpClient HttpClient
     */
    constructor(private httpClient: HttpClient) {
    }

addUserEvaluation(userEvaluation : UserEvaluation){
  return this.httpClient.post<UserEvaluation>(AcmURLConstants.ADD_USER_EVALUATION, userEvaluation);
}

findUserEvaluationByLoanId(loanId: number) {
  const headers = new HttpHeaders({Authorization: 'Bearer ' + localStorage.getItem('currentUser')});
  return this.httpClient.get<UserEvaluation[]>(`${AcmURLConstants.FIND_USER_EVALUATION_BY_LOAN_ID}${loanId}`,{ headers });
}

}
