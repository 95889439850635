import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreditService } from 'src/app/ib-services/credit.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { ProductEntity } from 'src/app/shared/Entities/product.entity copy';
import * as nodeIRR from 'node-irr';
import { TranslateService } from '@ngx-translate/core';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';
import { CalculationType } from 'src/app/shared/mb-constants/ib_constants';
import { LoanService } from 'src/app/ib-services/loan.service';

@Component({
  selector: 'app-period-selection',
  templateUrl: './period-selection.component.html',
  styleUrls: ['./period-selection.component.scss'],
})
export class PeriodSelectionComponent implements OnInit {

  public loan: LoanEntityIB;
  public invert = false;
  public showTicks = false;
  public valueTerm: number;
  public repaymentMonthly: number;
  public stepTerm = 1;
  public step = 1;
  public autoTicks = false;
  public disabled = false;
  public thumbLabel = true;
  public vertical = false;
  public tickInterval = 1;
  public lang: string;
  public product: ProductEntity;
  public minAmountProduct: number; // = 0
  public maxAmountProduct: number;
  public minTermProduct: number; // = 1
  public maxTermProduct: number; // = 15
  public valueAmount: number; // = 0
  public maxInstallment: number; // = 500
  public maxAmountGeneral: number; // Calculated from Backend
  public maxAmount: number;
  public minAmount: number;
  public maxTerm: number;
  public minTerm: number;
  public interestAmount: number = 0;
  public irrRate: number;
  public aprRate: number;
  public fees: number;
  public installmentByYear: number = 12;
  public firstDueDate: Date;
  public lastDueDate: Date;
  public greenFlag: Boolean = false;
  public redFlag: Boolean = false;
  public showValuesConfirmation: Boolean = false;
  public calculationType : string;

  constructor( private router: Router,private translate: TranslateService, public sharedService: SharedService, private creditService: CreditService,
    private datePipe: DatePipe,private paramService : ParametrageService,private loanService : LoanService){}

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    /** Retrieve Calculation Type */
    this.paramService.getEnvirementValueByKey("INSTALLMENT_CALCULATE_TYPE").subscribe(res => {
        this.calculationType = res.value;
    if (!this.sharedService.getLoanDeal()) {
      this.router.navigate(['login']);
    } else {
      this.loan = this.sharedService.getLoanDeal();
      this.maxInstallment = this.loan.maxInstallment;
      this.product = this.loan.productDTO;
      this.maxTermProduct = this.product.productDetailsDTOs[0].maximumTerm;
      this.minTermProduct = this.product.productDetailsDTOs[0].minimumTerm;
      this.maxAmountProduct = this.product.productDetailsDTOs[0].maximumAmount;
      this.minAmountProduct = this.product.productDetailsDTOs[0].minimumAmount;
      this.initValues();
      this.firstDueDate = new Date(
        this.datePipe.transform(this.loan.initialPaymentDate, 'yyyy-MM-dd')
      );
      this.calculateLastDueDate();
    }
    });

  }

  calculateLastDueDate() {
    let newDate = new Date(
      this.datePipe.transform(this.loan.initialPaymentDate, 'yyyy-MM-dd')
    );
    let lastDueDateCalc = newDate.setMonth(
      this.firstDueDate.getMonth() + this.valueTerm
    );
    this.lastDueDate = new Date(
      this.datePipe.transform(lastDueDateCalc, 'yyyy-MM-dd')
    );
  }

  initValues() {
    /** Set Term Values */
    this.maxTerm = this.maxTermProduct;
    this.minTerm = this.minTermProduct;

    /** Set Amount Values */
    if (this.minAmountProduct >= this.maxInstallment)
      this.minAmount = this.maxInstallment;
    this.maxAmountGeneral = this.maxInstallment * this.maxTerm;
    if (this.maxAmountGeneral >= this.maxAmountProduct) {
      this.maxAmount = this.maxAmountProduct;
    } else {
      this.maxAmount = this.maxAmountGeneral;
    }
    if (this.maxInstallment > this.minAmountProduct) {
      this.minAmount = this.maxInstallment;
    } else {
      this.minAmount = this.minAmountProduct;
    }
    this.valueAmount = this.maxAmount;
    this.maxTerm = this.maxTermProduct;
    this.valueTerm = this.maxTerm;
    this.fees = (this.product.issueFeePercentage1 * this.valueAmount) / 100;
    this.onInputChange();
  }

  /** Changes when amount is changed */
  updateAmount(event) {
    this.onInputChange();
  }

  /** Changes when term is changed */
  updateTerm(event) {
    if (event === null || event === 0){this.valueTerm = this.minTerm;}
    if (event > this.maxTerm || event === 0){this.valueTerm = this.maxTerm;}
    if (event < this.minTerm || event === 0){this.valueTerm = this.minTerm;}
    this.calculateLastDueDate();
    this.onInputChange();
  }

  /** Loan Details Confirmation */
  async next() {
    let loan = this.sharedService.getLoanDeal();
    loan.stepPath = 'sign-contract';
    loan.actionToAcm = 'APPROVE';
    loan.termPeriodNum = this.valueTerm;
    loan.normalPayment = this.repaymentMonthly;
    loan.approvelAmount = this.valueAmount;
    loan.applyAmountTotal = this.valueAmount;
    loan.totalInterest = this.interestAmount;

    /** Check on apr and affectiveRate in back end */
    loan.apr = this.aprRate;
    loan.effectiveIntRate = this.irrRate;

    let date = loan.initialPaymentDate;
    let formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    loan.applyDate = formattedDate;
    this.loanService.updateLoan(loan).subscribe((res) => {
      this.sharedService.setLoanDeal(res);
      this.sharedService.showInfo(
        this.translate.instant('LABELS.sanad_call_check')
      );
      this.router.navigate(['/' + PathConstants.PATH_HOME]);
    });
  }

  reset() {
    this.initValues();
    this.calculateLastDueDate();
  }

  /** Calculate repaymentDate */
  onInputChange() {
    if ( this.calculationType == CalculationType.RATE ) {
      // let rate = this.product.flatInterestRate > 1 ? this.product.flatInterestRate/100 : this.product.flatInterestRate;
      let rate = this.loan.productRate == 0 ? this.product.rate : this.loan.productRate ;
      let tauxMensuel = rate / 12;
      let repayment = ( (tauxMensuel * this.valueAmount) / (1 - Math.pow(1 + tauxMensuel, -this.valueTerm))
      ).toFixed(3);
      let totalAmount = parseFloat(repayment) * ((1 - Math.pow(1 + tauxMensuel, -this.valueTerm)) / tauxMensuel);
      this.valueAmount = Math.round(totalAmount);
      this.repaymentMonthly = Math.abs(parseFloat(repayment));
      this.interestAmount = Number((this.valueAmount * tauxMensuel).toFixed(3));
      this.flagsChecker();
      this.calculateAPR();
    }else if ( this.calculationType == CalculationType.FLAT ) {
      let frequencePaiement = 1;
      let deferredPeriod = 0;
      let frequenceInterest = 1;
      let flatRate: number = 0;
      const rateToUse = this.loan.productRate
        ? (this.loan.productRate !== this.product.flatInterestRate
            ? this.loan.productRate
            : this.product.flatInterestRate)
        : this.product.flatInterestRate;

      flatRate = rateToUse > 1 ? rateToUse / 100 : rateToUse;
      let tauxInteretMensuel =
        (flatRate * frequenceInterest) / 12;
      let repayment = (
        (this.valueAmount *
          (tauxInteretMensuel + 1 / (this.valueTerm / frequencePaiement)) *
          (this.valueTerm / frequencePaiement)) /
        (this.valueTerm / frequencePaiement - deferredPeriod)
      ).toFixed(3);
      let totalAmount =
        parseFloat(repayment) /
        (((tauxInteretMensuel + 1 / (this.valueTerm / frequencePaiement)) *
          (this.valueTerm / frequencePaiement)) /
          (this.valueTerm / frequencePaiement - deferredPeriod));
      this.valueAmount = Math.round(totalAmount);
      this.interestAmount = Number(( parseFloat(repayment) * (this.valueTerm / frequencePaiement - deferredPeriod) -
          this.valueAmount
        ).toFixed(3)
      );
      this.repaymentMonthly = Math.abs(parseFloat(repayment));
      this.flagsChecker();
      this.calculateAPR();
    }
    this.fees = (this.product.issueFeePercentage1 * this.valueAmount) / 100;

  }

  calculateAPR() {
    const data = [];
    data.push(-this.valueAmount);
    for (let i = 0; i < this.valueTerm; i++) {
      data.push(this.repaymentMonthly);
    }
    this.irrRate = nodeIRR.irr(data);
    this.aprRate = Math.pow(1 + this.irrRate, this.installmentByYear) - 1;
  }

  flagsChecker() {
    if (this.repaymentMonthly > this.maxInstallment) {
      this.greenFlag = false;
      this.redFlag = true;
    } else {
      this.greenFlag = true;
      this.redFlag = false;
    }
  }

  confirmation() {
    if (this.greenFlag) {
      this.showValuesConfirmation = true;
    }
  }

  /** Tooltip Positioning */
  get tooltipPositionAmount() {
    const percentage =((this.valueAmount - this.minAmount) / (this.maxAmount - this.minAmount)) * 100;
    return `calc(${percentage}% - 50%)`;
  }

  get tooltipPositionTerm() {
    const percentage = ((this.valueTerm - this.minTerm) / (this.maxTerm - this.minTerm)) * 100;
    return `calc(${percentage}% - 50%)`;
  }

}
