import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'src/app/ib-services/document.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { DocumentTypeEntity } from 'src/app/shared/Entities/DocumentType.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { LoanDocumentEntity } from 'src/app/shared/Entities/LoanDocument.entity';
import { SettingDocumentTypeEntity } from 'src/app/shared/Entities/SettingDocumentType.entity';
import { AcmConstants } from 'src/app/shared/mb-constants/mb-constants';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  public loan: LoanEntityIB;
  public customer: CustomerEntity;
  public customerDocuments: LoanDocumentEntity[] = [];
  public showContract: boolean = false;
  public contractSrc: string;
  public zoomLevel: number = 0.8;

  constructor(
    private sharedService: SharedService,
    private documentService: DocumentService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.loan = this.sharedService.getLoanDeal();
    this.customer = this.sharedService.getCustomer();
    this.downloadDoc();
  }

  downloadDoc() {
    const document: LoanDocumentEntity = new LoanDocumentEntity();
    document.idCustomer = this.customer.ibCustomerId;
    document.loanId = this.loan.idIbLoan;

    document.settingDocumentTypeDTO = new SettingDocumentTypeEntity();

    this.documentService
      .getDocumentsByLoan(document)
      .subscribe((arrayDocuments) => {
        this.customerDocuments = arrayDocuments;
        console.log('Documents: ', this.customerDocuments);
      });
  }

  getNeededDocument(documentName: string, action: string) {
    if (action === 'view') {
      let filteredDoc = this.customerDocuments.find(
        (res) => res.settingDocumentTypeDTO.libelle === documentName
      );
      if (filteredDoc) {
        this.view(filteredDoc.file,filteredDoc.idDocument);
      } else {
        this.sharedService.showInfo(
          this.translate.instant('ERROR.document_not_available')
        );
      }
    }else {
      if (documentName === 'Schedule') {
        this.documentService
          .getDocumentByReportName(this.loan.accountNumber, 'schedule')
          .subscribe(
            (res) => {
              const blob = new Blob([res], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              let generatedScheduleName =
                'Schedule_Report_' + new Date().toISOString();
              link.download = generatedScheduleName + '.pdf';
              link.click();

              window.URL.revokeObjectURL(url);
            },
            (error) => {
              console.log(error);
              this.sharedService.showError(
                this.translate.instant('ERROR.error_schedule_report')
              );
            }
          );
      } else {
        let filteredDoc = this.customerDocuments.find(
          (res) => res.settingDocumentTypeDTO.libelle === documentName
        );
        if (filteredDoc) {
          this.download(filteredDoc);
        } else {
          this.sharedService.showInfo(
            this.translate.instant('ERROR.document_not_available')
          );
        }
      }
    }
  }

  download(document: LoanDocumentEntity) {
    if (document.idDocument !== null) {
      this.documentService
        .getDocument(
          document.idDocument.toString(),
          this.loan.idIbLoan.toString(),
          ''
        )
        .subscribe((res: any) => {
          if (res instanceof Blob) {
            if (res.size == 0) {
              return;
            }
            if (res.type === 'application/pdf') {
              const reader = new FileReader();
              reader.onloadend = () => {
                const dataUrl = reader.result as string;
                this.contractSrc = dataUrl;
              };
              reader.readAsDataURL(res);
              this.showContract = true;
            } else {
              // Convert Blob to data URL
              const fileData = [res];
              const extension = document.name.substring(
                document.name.indexOf('.') + 1
              );
              const documentType = this.getMimeType(extension);
              const blob = new Blob(fileData, { type: documentType });
              const url = window.URL.createObjectURL(blob);
              window.open(url, '_blank');
            }
          }
        });
    } else {
      // Convert file to data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result as string;
        this.contractSrc = dataUrl;
      };
      reader.readAsDataURL(this.customerDocuments[0].file);
      this.showContract = true;
    }
  }

  view(file, idDocumentGED) {
    if (idDocumentGED !== null && idDocumentGED !== '') {
      this.documentService
        .getDocument(idDocumentGED, this.loan.idIbLoan.toString(), '')
        .subscribe((res: any) => {
          if (res instanceof Blob) {
            if (res.size == 0) {
              return;
            }
            const fileURL = URL.createObjectURL(res);
            this.contractSrc = fileURL;
            this.showContract = true;
          }
        });
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileURL = reader.result as string;
        this.contractSrc = fileURL;
        this.showContract = true;
      };
      reader.readAsDataURL(file);
    }
  }

  closeContract() {
    this.showContract = false;
  }

  increaseZoom() {
    this.zoomLevel += 0.1;
  }

  decreaseZoom() {
    this.zoomLevel = Math.max(0.1, this.zoomLevel - 0.1);
  }

  getMimeType(fileExtension: string) {
    switch (fileExtension) {
      case 'pdf':
        return this.sharedService.typeMimes[0];
      case 'docx':
        return this.sharedService.typeMimes[7];
      case 'doc':
        return this.sharedService.typeMimes[1];
      case 'xlsx':
        return this.sharedService.typeMimes[8];
      case 'xls':
        return this.sharedService.typeMimes[2];
      case 'pptx':
        return this.sharedService.typeMimes[5];
      case 'ppt':
        return this.sharedService.typeMimes[6];
      case 'jpeg':
        return this.sharedService.typeMimes[3];
      case 'png':
        return this.sharedService.typeMimes[4];
      case 'jpg':
        return this.sharedService.typeMimes[3];
      default:
        return '';
    }
  }
}
