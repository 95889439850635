<div id="documents" class="bg-2 vh-100 vw-100 px-3">
  <app-screen-title [title]="'BUTTONS.documents'" [color]="'color1'"
    [backTo]="'/loan-schedule-details'"></app-screen-title>
  <div id="documents-cards" class="mt-4">

    <div class="card cu-card p-2 py-3 mb-3">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons  m-1" src="assets/images/icons/sheets.png" alt="sheets">
          </div>
        </div>
        <div class="col-7 p-1">
          {{'DOCUMENT.loan_contract' | translate}}
        </div>
        <div class="col-3 d-flex align-items-center justify-content-between">
          <img class="ib-icons m-1 color-change" src="assets/images/icons/download_icon1.png" alt="upload"
            (click)="getNeededDocument('عقد القرض','download')">
          <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye" (click)="getNeededDocument('عقد القرض','view')">
        </div>

      </div>
    </div>

    <div class="card cu-card p-2 py-3 mb-3">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons  m-1" src="assets/images/icons/sheets.png" alt="sheets">
          </div>
        </div>
        <div class="col-7 p-1">
          {{'LABELS.PROOF_OF_DEBT_LETTER' | translate}}
        </div>
        <div class="col-3 d-flex align-items-center justify-content-between">
          <img class="ib-icons m-1 color-change" src="assets/images/icons/download_icon1.png" alt="upload"
            (click)="getNeededDocument('Proof Of Debt Letter','download')">
          <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye" (click)="getNeededDocument('Proof Of Debt Letter','view')" >
        </div>
      </div>
    </div>

    <div class="card cu-card p-2 py-3 mb-3">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons  m-1" src="assets/images/icons/sheets.png" alt="sheets">
          </div>
        </div>
        <div class="col-7 p-1">
          {{'LABELS.CLEARANCE_LOAN_DOCUMENT' | translate}}
        </div>
        <div class="col-3 d-flex align-items-center justify-content-between">
          <img class="ib-icons m-1 color-change" src="assets/images/icons/download_icon1.png" alt="upload"
            (click)="getNeededDocument('Clearance Loan Document','download')">
          <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye" (click)="getNeededDocument('Clearance Loan Document','view')">
        </div>
      </div>
    </div>

    <div class="card cu-card p-2 py-3 mb-3">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons  m-1" src="assets/images/icons/sheets.png" alt="sheets">
          </div>
        </div>
        <div class="col-7 p-1">
          {{'DOCUMENT.tawkeel_murabha' | translate}}
        </div>
        <div class="col-3 d-flex align-items-center justify-content-between">
          <img class="ib-icons m-1 color-change" src="assets/images/icons/download_icon1.png" alt="upload"
            (click)="getNeededDocument('MURABHA_CERTIFICATE','download')">
          <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye" (click)="getNeededDocument('MURABHA_CERTIFICATE','view')">
        </div>
      </div>
    </div>

    <div class="card cu-card p-2 py-3 mb-3">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons  m-1" src="assets/images/icons/sheets.png" alt="sheets">
          </div>
        </div>
        <div class="col-8 p-1">
          {{'DOCUMENT.schedule' | translate }}
        </div>
        <div class="col-2">
          <img class="ib-icons m-1 color-change" src="assets/images/icons/download_icon1.png" alt="upload"
            (click)="getNeededDocument('Schedule','download')">
          <!-- <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye"> -->
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="!customerDocuments || customerDocuments.length == 0">
    <div class="pt-4 flc inline-flex align-items-center">
      <img class="img-logo-sanad op-20" style="width: 150px;" src="assets/images/note.png" alt="My Image">
    </div>
    <label class="label-info flc">{{'INFO.no_document_found' | translate}}</label>
  </div>


  <div *ngIf="showContract">
    <div class="fullscreen" style="padding-top: 60px">
      <pdf-viewer [src]="contractSrc" [show-all]="true" class="pdfViewer" [zoom]="zoomLevel"
        [render-text]="true"></pdf-viewer>
      <div class="confirmation" style="display: flex; justify-content: space-between; gap: 10px;">
        <button class="btn col-4 white py-1" style="background-color: #213592" (click)="decreaseZoom()"> - </button>
        <button class="btn col-4 white py-1" style="background-color: #213592" (click)="increaseZoom()"> + </button>
        <button class="btn btn-danger col-4 white py-1" (click)="closeContract()">{{'BUTTON.close' |
          translate}}</button>
      </div>
    </div>
  </div>


</div>
